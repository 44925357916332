var Airtable = require('airtable');
Airtable.configure({
    endpointUrl: 'https://api.airtable.com',
    apiKey: 'patUujExPHLGbGfS3.9f26b72abc1e0f505d0320d980216da623e0bd426b9133d341e633a02a1c5be8'
});
var base = Airtable.base('app6GHxtqv6bp7oBk');

window.Airwaves = {}; // global Object container; don't use var

Airwaves.searchInput = document.getElementById('input');
// Airwaves.searchInput.focus(); don't do this anymore

Airwaves.player = document.getElementById('player');
Airwaves.audio = document.getElementById('audio');
Airwaves.alert = document.getElementById('alert');
Airwaves.audioSource = document.createElement('source');

Airwaves.searchResults = document.getElementById('searchResults');

Airwaves.searchForm = document.querySelector("#search-form");

Airwaves.totalTracks = 0;

Airwaves.queuePast = [];
Airwaves.queueFuture = [];
Airwaves.queueCurrent = [];

Airwaves.trackCache = [];

Airwaves.audio.onended = (event) => {

  Airwaves.ended();

};

// Shuffle to random track
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

// Configure tracksColumns
const tracksColumns = {
  recordID: "Record ID (Airtable)",
  googleDriveURL: "Google Drive Direct Play URL",

  title: "Title",

  artists: "Artists",
  featuredArtists: "Linked Featured Artists Display Names",
  remixArtists: "Linked Remix / VIP Artist Display Names",

  trackName: "Track Name",
  trackNamePlus: "Track Name + Variants",
  trackType: "Track Type",
  trackFile: "Track File",

  album: "Album",
  albumArtwork: "Album Artwork",
};

const albumsColumns = {
  recordID: "Record ID (Airtable)",

  name: "Name",

  trackArtists: "Track Artists",

  trackIDs: "Track IDs",
  trackTypes: "Track Types",
  trackCount: "# Tracks",

  tracksAlbumArtworks: "Track's Artworks"
};

const playlistsColumns = {
  recordID: "Record ID (Airtable)",

  name: "Name",

  trackArtists: "Playlist Artists List",

  trackIDs: "Track IDs",
  trackCount: "# Tracks",

  tracksAlbumArtworks: "Track's Artworks"
};


// Fields to query Tracks for
const tracksFieldsQuery = [
  tracksColumns["recordID"],
  tracksColumns["googleDriveURL"],

  tracksColumns["title"],

  tracksColumns["artists"],
  tracksColumns["featuredArtists"],
  tracksColumns["remixArtists"],

  tracksColumns["trackName"],
  tracksColumns["trackNamePlus"],
  tracksColumns["trackType"],
  tracksColumns["trackFile"],

  tracksColumns["album"],
  tracksColumns["albumArtwork"]
];

// Fields to query Albums for
const albumsFieldsQuery = [
  albumsColumns["recordID"],

  albumsColumns["name"],

  albumsColumns["trackArtists"],

  albumsColumns["trackIDs"],
  albumsColumns["trackTypes"],
  albumsColumns["trackCount"],

  albumsColumns["tracksAlbumArtworks"]
];

// Fields to query Playlists for
const playlistsFieldsQuery = [
  playlistsColumns["recordID"],

  playlistsColumns["name"],

  playlistsColumns["trackArtists"],

  playlistsColumns["trackIDs"],
  playlistsColumns["trackCount"],

  playlistsColumns["tracksAlbumArtworks"]
];

function componentTemplate(component, variant, data) {

  if(
    component == "trackItem"
  ) {
    let template = "<li>";
      template = template + "<div class='c-track-result' data-recordid='" + data["Record ID"] + "'>"
        template = template + "<button type='button' class='" + (variant == "noControls" ? 'cursor-default' : '') + " c-track-result__thumbnail thumbnail' data-thumbnail-recordid='" + data["Record ID"] + "' loading='lazy' style='background-image: url(" + data["Album Artwork URL"] + ");' " + (variant == "noControls" ? 'tabindex="-1"' : 'data-action="playTrack"') + "  data-recordID='" + data["Record ID"] + "'></button>";

        template = template + "<button type='button' class='" + (variant == "noControls" ? 'state-invisible' : '') + " c-track-result__button c-track-result__button--play' data-action='playTrack' data-recordID='" + data["Record ID"] + "'>";
          template = template + '<svg class="c-track-result__button-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 8.64L13.77 12L8.5 15.36V8.64ZM6.5 5V19L17.5 12L6.5 5Z" fill="currentColor"/></svg>';
        template = template + "</button>";

        template = template + "<div class='c-track-result__meta'>";
        template = template + "<p class='c-track-result__track-name'>" + data["Track Name"] + "</p>";

        template = template + "<div class='c-track-result__meta-links'>";        

        if(data["Artists"]) {
        
          template = template + '<p class="c-track-result__artists"><svg class="c-track-result__inline-icon u-color-cryan-500" viewBox="0 0 24 24"><path fill="currentColor" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg>' + data["Artists"] + '</p>';

        }

        if(data["Remix Artists"] || data["Track Type"]) {
          template = template + '<p class="c-track-result__remix-artists"><svg class="c-track-result__inline-icon u-color-plink-500" viewBox="0 0 24 24"><path fill="currentColor" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg>' + data["Remix Artists"] + " " + data["Track Type"] + '</p>';
        }
        
        if(data["Featured Artists"]) {
          template = template + "<p class='c-track-result__featured-artists'>" + data["Featured Artists"] + "</p>";
        }

        if(data["Album"]) {
          template = template + '<p class="c-track-result__album"><svg class="c-track-result__inline-icon" viewBox="0 0 24 24"><path fill="currentColor" d="M12,11A1,1 0 0,0 11,12A1,1 0 0,0 12,13A1,1 0 0,0 13,12A1,1 0 0,0 12,11M12,16.5C9.5,16.5 7.5,14.5 7.5,12C7.5,9.5 9.5,7.5 12,7.5C14.5,7.5 16.5,9.5 16.5,12C16.5,14.5 14.5,16.5 12,16.5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>' + data["Album"] + '</p>';
        }

        template = template + "</div>";
        template = template + "</div>";

        if(variant !== "noControls") {

          template = template + "<div class='c-track-result__controls'>";
            
            template = template + "<button type='button' class='c-track-result__button c-track-result__button--addToQueue' data-action='addToQueue' data-recordID='" + data["Record ID"] + "'>";
              template = template + '<svg class="c-track-result__button-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 9H2.5V11H13.5V9ZM13.5 5H2.5V7H13.5V5ZM17.5 13V9H15.5V13H11.5V15H15.5V19H17.5V15H21.5V13H17.5ZM2.5 15H9.5V13H2.5V15Z" fill="currentColor"/></svg>';
            template = template + "</button>";
          template = template + "</div>";

        }

      template = template + "</div>";
    template = template + "</li>";

    return template;
  }
  else if (
    component == "albumItem"
  ) {
    let template = "<li>";
      template = template + "<div class='c-track-result' data-recordid='" + data["Record ID"] + "'>"
        template = template + "<button type='button' class='" + (variant == "noControls" ? 'cursor-default' : '') + " c-track-result__thumbnail thumbnail thumbnail--album' data-thumbnail-recordid='" + data["Track IDs"] + "' loading='lazy' style='background-image: url(" + data["Album Artwork"] + ");' " + (variant == "noControls" ? 'tabindex="-1"' : 'data-action="playTrack"') + "  data-recordID='" + data["Track IDs"] + "'></button>";

        template = template + "<button type='button' class='" + (variant == "noControls" ? 'state-invisible' : '') + " c-track-result__button c-track-result__button--play' data-action='playTrack' data-recordID='" + data["Track IDs"] + "'>";
          template = template + '<svg class="c-track-result__button-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 8.64L13.77 12L8.5 15.36V8.64ZM6.5 5V19L17.5 12L6.5 5Z" fill="currentColor"/></svg>';
        template = template + "</button>";

        template = template + "<div class='c-track-result__meta'>";
        template = template + "<p class='c-track-result__track-name'>" + data["Name"] + "</p>";

        template = template + "<div class='c-track-result__meta-links'>";

        if(data["# Tracks"]) {
        
          template = template + '<p class="c-track-result__artists u-color-maeve-850"><svg class="c-track-result__inline-icon" viewBox="0 0 24 24"><path fill="currentColor" d="M12,11A1,1 0 0,0 11,12A1,1 0 0,0 12,13A1,1 0 0,0 13,12A1,1 0 0,0 12,11M12,16.5C9.5,16.5 7.5,14.5 7.5,12C7.5,9.5 9.5,7.5 12,7.5C14.5,7.5 16.5,9.5 16.5,12C16.5,14.5 14.5,16.5 12,16.5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>' + data["# Tracks"] + ' tracks</p>';

        }

        if(data["Track Artists"]) {
        
          template = template + '<p class="c-track-result__artists"><svg class="c-track-result__inline-icon u-color-cryan-500" viewBox="0 0 24 24"><path fill="currentColor" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg>' + data["Track Artists"] + '</p>';

        }

        template = template + "</div>";
        template = template + "</div>";

        if(variant !== "noControls") {

          template = template + "<div class='c-track-result__controls'>";
            
            template = template + "<button type='button' class='c-track-result__button c-track-result__button--addToQueue' data-action='addToQueue' data-recordID='" + data["Track IDs"] + "'>";
              template = template + '<svg class="c-track-result__button-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 9H2.5V11H13.5V9ZM13.5 5H2.5V7H13.5V5ZM17.5 13V9H15.5V13H11.5V15H15.5V19H17.5V15H21.5V13H17.5ZM2.5 15H9.5V13H2.5V15Z" fill="currentColor"/></svg>';
            template = template + "</button>";
          template = template + "</div>";

        }

      template = template + "</div>";
    template = template + "</li>";

    return template;
  }

  else if (
    component == "playlistItem"
  ) {
    let template = "<li>";
      template = template + "<div class='c-track-result' data-recordid='" + data["Record ID"] + "'>"
        template = template + "<button type='button' class='" + (variant == "noControls" ? 'cursor-default' : '') + " c-track-result__thumbnail thumbnail thumbnail--album' data-thumbnail-recordid='" + data["Track IDs"] + "' loading='lazy' style='background-image: url(" + data["Album Artwork"] + ");' " + (variant == "noControls" ? 'tabindex="-1"' : 'data-action="playTrack"') + "  data-recordID='" + data["Track IDs"] + "'></button>";

        template = template + "<button type='button' class='" + (variant == "noControls" ? 'state-invisible' : '') + " c-track-result__button c-track-result__button--play' data-action='playTrack' data-recordID='" + data["Track IDs"] + "'>";
          template = template + '<svg class="c-track-result__button-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 8.64L13.77 12L8.5 15.36V8.64ZM6.5 5V19L17.5 12L6.5 5Z" fill="currentColor"/></svg>';
        template = template + "</button>";

        template = template + "<div class='c-track-result__meta'>";
        template = template + "<p class='c-track-result__track-name'>" + data["Name"] + "</p>";

        template = template + "<div class='c-track-result__meta-links'>";

        if(data["# Tracks"]) {
        
          template = template + '<p class="c-track-result__artists u-color-maeve-850"><svg class="c-track-result__inline-icon" viewBox="0 0 24 24"><path fill="currentColor" d="M12,11A1,1 0 0,0 11,12A1,1 0 0,0 12,13A1,1 0 0,0 13,12A1,1 0 0,0 12,11M12,16.5C9.5,16.5 7.5,14.5 7.5,12C7.5,9.5 9.5,7.5 12,7.5C14.5,7.5 16.5,9.5 16.5,12C16.5,14.5 14.5,16.5 12,16.5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" /></svg>' + data["# Tracks"] + ' tracks</p>';

        }

        if(data["Track Artists"]) {
        
          template = template + '<p class="c-track-result__artists"><svg class="c-track-result__inline-icon u-color-cryan-500" viewBox="0 0 24 24"><path fill="currentColor" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" /></svg>' + data["Track Artists"] + '</p>';

        }

        template = template + "</div>";
        template = template + "</div>";

        if(variant !== "noControls") {

          template = template + "<div class='c-track-result__controls'>";
            
            template = template + "<button type='button' class='c-track-result__button c-track-result__button--addToQueue' data-action='addToQueue' data-recordID='" + data["Track IDs"] + "'>";
              template = template + '<svg class="c-track-result__button-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 9H2.5V11H13.5V9ZM13.5 5H2.5V7H13.5V5ZM17.5 13V9H15.5V13H11.5V15H15.5V19H17.5V15H21.5V13H17.5ZM2.5 15H9.5V13H2.5V15Z" fill="currentColor"/></svg>';
            template = template + "</button>";
          template = template + "</div>";

        }

      template = template + "</div>";
    template = template + "</li>";

    return template;
  }

}

Airwaves.searchAirtable = function searchAirtable(searchTerm) {

  // console.log('searching for "' + searchTerm + '"');

  var cleanedSearchTerm = searchTerm.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, ' ').replace(/ {2,}/g, ' ');

  document.getElementById('searchResults').innerHTML = "";

  document.getElementById('searchResults').classList.add("is-loading");

  console.log("Searched for: " + searchTerm);
  console.log("Searched for (cleaned): " + cleanedSearchTerm);

  base('Tracks')
    .select({
      view: "🔍 Has Artist",
      filterByFormula: 'OR(FIND(LOWER("' + cleanedSearchTerm + '"), {Search Terms})>0)',
      fields: tracksFieldsQuery
    })
    .firstPage(function (err, records) {

      if (err) { console.error(err); return; }

      if ( records.length > 0 ) {

        var searchResultTracks = [];

        records.forEach(function (record) {

          console.log(record);

          var track = [];
          track["Title"] = "";
          track["Record ID"] = "";
          track["Play URL"] = "";
          track["Track Name"] = "";
          track["Track Type"] = "";
          track["Artists"] = "";
          track["Featured Artists"] = "";
          track["Remix Artists"] = "";
          track["Album"] = "";
          track["Album Artwork URL"] = "";

          if (tracksColumns["title"] in record.fields) {
            track["Title"] = record.get(tracksColumns["title"]);
          }

          if (tracksColumns["recordID"] in record.fields) {
            track["Record ID"] = record.get(tracksColumns["recordID"]);
          }

          // ["Play URL"]
          // Play URL = Track File [url] OR Google Drive URL
          if (tracksColumns["trackFile"] in record.fields) {
            // console.log("found trackfile in Airtable!");
            track["Play URL"] = record.get(tracksColumns["trackFile"])[0].url;
          } else {
            if(tracksColumns["googleDriveURL"] in record.fields)
            // console.log("found google drive trackfile!");
            track["Play URL"] = record.get(tracksColumns["googleDriveURL"]);
          }

          if (tracksColumns["trackName"] in record.fields) {
            track["Track Name"] = record.get(tracksColumns["trackName"]);
          }

          if (tracksColumns["trackType"] in record.fields) {
            track["Track Type"] = record.get(tracksColumns["trackType"]);
          }

          if (tracksColumns["artists"] in record.fields) {
            track["Artists"] = record.get(tracksColumns["artists"]);
          }

          if (tracksColumns["featuredArtists"] in record.fields) {
            track["Featured Artists"] = record.get(tracksColumns["featuredArtists"]);
          }

          if (tracksColumns["remixArtists"] in record.fields) {
            track["Remix Artists"] = record.get(tracksColumns["remixArtists"]);
          }

          if (tracksColumns["album"] in record.fields) {
            track["Album"] = record.get(tracksColumns["album"]);
          }

          if (tracksColumns["albumArtwork"] in record.fields) {
            // console.log("found album artwork url!");

            if("thumbnails" in record.get(tracksColumns["albumArtwork"])[0]) {
              // console.log("has thumbnails!");

              if("large" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
                // console.log("has large thumbnail!");
                track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.large.url;
              }

              else if("small" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
                // console.log("has small thumbnail!");
                track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.small.url;
              }
            }
            else {
              // console.log("no thumbnails, just fullsize!");
              track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].url;
            }
          }

          Airwaves.trackCache.push(track);

          var trackItemTemplate = componentTemplate("trackItem", "", track);

          searchResultTracks.push(trackItemTemplate);
        });

        document.getElementById('searchResults').classList.remove("is-loading");

        document.getElementById('searchResults').innerHTML = "<ul class='results__list results__list--grid'>" + searchResultTracks.join("") + "</ul>";

      } else {

        document.getElementById('searchResults').classList.remove("is-loading");

        document.getElementById('searchResults').innerHTML = "<p style='position: absolute; top: 50%; left: 50%; white-space: nowrap; transform: translate(-50%);'><span style='font-size: 3em;'>🤷‍♂️</span> No matching tracks</p>";

      }

    });

}


Airwaves.search = function search(e) {

  var input = document.getElementById('input').value;
  Airwaves.searchAirtable(input);

}

Airwaves.searchInput.addEventListener('focus', (event) => {
  document.body.classList.add("is-focused-search-input");
});

Airwaves.searchInput.addEventListener('blur', (event) => {
  document.body.classList.remove("is-focused-search-input");
});

Airwaves.removeFromQueue = function removeFromQueue(recordID) {

  for (var i = Airwaves.queueFuture.length - 1; i >= 0; --i) {

    // console.log("item in queueFuture:");
    // console.log(Airwaves.queueFuture[i]["Record ID"]);

    if (Airwaves.queueFuture[i]["Record ID"] == recordID) {
      Airwaves.queueFuture.splice(i, 1);
    }
  }

  renderQueueFuture();

}

Airwaves.clearQueue = function clearQueue() {

  Airwaves.sendAlert('Cleared upcoming queue');
  
  Airwaves.queueFuture = [];
  renderQueueFuture();
}

Airwaves.shuffleQueue = function shuffleQueue() {

  Airwaves.sendAlert('Shuffling upcoming queue');

  let currentQueueFuture = Airwaves.queueFuture;
  
  console.log("Current queue");
  console.log(currentQueueFuture);

  let shuffledQueueFuture = currentQueueFuture.sort(() => Math.random() - 0.5);

  console.log("Shuffled queue");
  console.log(shuffledQueueFuture);
  
  Airwaves.queueFuture = shuffledQueueFuture;
  renderQueueFuture();
}

Airwaves.sendAlert = function sendAlert(text) {
  
  // remove 'open' attr to fadeout
  Airwaves.alert.removeAttribute('open');

  // remove innerHMTL after fadeout
  document.getElementById("alertText").innerHTML = "";

  // use text as new innerHTML
  document.getElementById("alertText").innerHTML = text;

  // add 'open' attr to fadein
  Airwaves.alert.setAttribute('open', "");

  // after X delay, remove 'open' attr to fadeout
  setTimeout(function(){
    Airwaves.alert.removeAttribute('open');
  }, 5000);
}


function reloadAudio(track) {
  
  console.log(track);

  Airwaves.sendAlert('Now playing: ' +  track["Track Name"]);

  document.getElementById('player').classList.add("is-hidden");

  Airwaves.audioSource.setAttribute('src', track["Play URL"]);
  Airwaves.audioSource.setAttribute('id', "source");

  Airwaves.audio.appendChild(Airwaves.audioSource);
  Airwaves.audio.load();
  playAudio();

  document.querySelector('.playPause').classList.remove('paused');

  document.title = track["Title"] + " - Airwaves";

  setTimeout(() => {
    if(
      track["Album Artwork URL"] !== undefined
    ) 
    {
      document.getElementById("currentAlbumArtwork").style.backgroundImage = "url('" + track["Album Artwork URL"] + "')";
    }
    else {
      document.getElementById("currentAlbumArtwork").style.backgroundImage = "";
    }

    document.getElementById("currentArtists").innerHTML = track["Artists"];
    document.getElementById("currentTrack").innerHTML = track["Track Name"];
    document.getElementById("currentAlbum").innerHTML = track["Album"];

    document.getElementById('player').classList.remove("is-hidden");

    let styleThumbnail = document.getElementById('style-thumbnail');
    styleThumbnail?.remove();
    
    document.head.insertAdjacentHTML("beforeend", `<style id='style-thumbnail'>[data-thumbnail-recordid='` + track["Record ID"]  + `'] { animation: playingThumbnail 2.91s linear infinite; } </style>`)
  }, "200");


  // move queueCurrent to queuePast
  if (Airwaves.queueCurrent["Record ID"]) {
    Airwaves.queuePast.push(Airwaves.queueCurrent);
  }

  // console.log("Airwaves.queuePast:");
  // console.table(Airwaves.queuePast);

  renderQueuePast();

  // reset, then set queueCurrent
  Airwaves.queueCurrent = [];
  Airwaves.queueCurrent["Record ID"] = track["Record ID"];
  Airwaves.queueCurrent["Track Name"] = track["Track Name"];
  Airwaves.queueCurrent["Artists"] = track["Artists"];
  Airwaves.queueCurrent["Featured Artists"] = track["Featured Artists"];
  Airwaves.queueCurrent["Remix Artists"] = track["Remix Artists"];
  Airwaves.queueCurrent["Track Type"] = track["Track Type"];
  Airwaves.queueCurrent["Album"] = track["Album"];
  Airwaves.queueCurrent["Album Artwork URL"] = track["Album Artwork URL"];

  // console.log("Airwaves.queueCurrent:");
  // console.table(Airwaves.queueCurrent);

  if ('mediaSession' in navigator) {

    navigator.mediaSession.metadata = new MediaMetadata({
      title: track["Track Name"],
      artist: track["Artists"],
      album: track["Album"],
      artwork: [
        { src: track["Album Artwork URL"], type: 'image/png' },
      ]
    });
  
    navigator.mediaSession.setActionHandler('play', Airwaves.playPause);
    navigator.mediaSession.setActionHandler('pause', Airwaves.playPause);
    navigator.mediaSession.setActionHandler('seekbackward', Airwaves.seekBackward);
    navigator.mediaSession.setActionHandler('seekforward', Airwaves.seekForward);
    navigator.mediaSession.setActionHandler('nexttrack', Airwaves.skipTrack);
    navigator.mediaSession.setActionHandler('previoustrack', Airwaves.skipTrack);
  }

  renderQueueCurrent();

  if(Airwaves.queueFuture.length < 1) {
    populateQueueFuture();
  }

  Airwaves.removeFromQueue(track["Record ID"]);
}

Airwaves.play = function play(recordIDs, callback) {

  let recordToPlay = "";
  let recordsToAddToQueue = "";

  // console.log(recordIDs);

  if (recordIDs.includes(',')) {

    // console.log('recordIDs:');
    // console.log(recordIDs);
    
    // it's a list, push each one to the array
    recordIDs = recordIDs.split(',');

    // console.log('recordIDs after split:');
    // console.log(recordIDs);

    recordToPlay = recordIDs.slice(0,1);

    // console.log('recordToPlay:');
    // console.log(recordToPlay);
  
    recordsToAddToQueue = recordIDs.slice(1,recordIDs.length);

    // console.log('recordsToAddToQueue');
    // console.log(recordsToAddToQueue);

    // console.log('recordsToAddToQueue toString():');
    // console.log(recordsToAddToQueue.toString());

    Airwaves.addToQueue(recordsToAddToQueue.toString());
    
  } else {
    recordToPlay = recordIDs;
  }

  var track = [];
  track["Record ID"] = "";
  track["Play URL"] = "";
  track["Track Name"] = "";
  track["Track Type"] = "";
  track["Artists"] = "";
  track["Featured Artists"] = "";
  track["Remix Artists"] = "";
  track["Album"] = "";
  track["Album Artwork URL"] = "";

  var cachedTrack = Airwaves.trackCache.find(track => track["Record ID"] === recordToPlay);
  
  if(
    cachedTrack
  ) 
  { 
    console.log("Found cached track:");
    console.log(cachedTrack);

    track["Title"] = cachedTrack["Title"];
    track["Play URL"] = cachedTrack["Play URL"];
    track["Record ID"] = cachedTrack["Record ID"];
    track["Track Name"] = cachedTrack["Track Name"];
    track["Track Type"] = cachedTrack["Track Type"];
    track["Artists"] = cachedTrack["Artists"];
    track["Featured Artists"] = cachedTrack["Featured Artists"];
    track["Remix Artists"] = cachedTrack["Remix Artists"];
    track["Album"] = cachedTrack["Album"];
    track["Album Artwork URL"] = cachedTrack["Album Artwork URL"];

    reloadAudio(track);
  }

  else {

    console.log("No cached track, go get " + recordToPlay);

    base('Tracks').find(recordToPlay).then(record => {

      if (record.fields[tracksColumns["title"]]) {
        track["Title"] = record.fields[tracksColumns["title"]];
      }

      if (record.fields[tracksColumns["recordID"]]) {
        track["Record ID"] = record.fields[tracksColumns["recordID"]];
      }
      
      // ["Play URL"]
      // Play URL = Track File [url] OR Google Drive URL
      if (tracksColumns["trackFile"] in record.fields) {
        // console.log("found trackfile in Airtable!");
        track["Play URL"] = record.fields[tracksColumns["trackFile"]][0].url;
      } else {
        if(tracksColumns["googleDriveURL"] in record.fields)
        // console.log("found google drive trackfile!");
        track["Play URL"] = record.fields[tracksColumns["googleDriveURL"]];
      }

      if (record.fields[tracksColumns["trackNamePlus"]]) {
        track["Track Name"] = record.fields[tracksColumns["trackNamePlus"]];
      }

      if (record.fields[tracksColumns["trackType"]]) {
        track["Track Type"] = record.fields[tracksColumns["trackType"]];
      }

      if (record.fields[tracksColumns["artists"]]) {
        track["Artists"] = record.fields[tracksColumns["artists"]];
      }

      if (record.fields[tracksColumns["featuredArtists"]]) {
        track["Featured Artists"] = record.fields[tracksColumns["featuredArtists"]];
      }

      if (record.fields[tracksColumns["remixArtists"]]) {
        track["Remix Artists"] = record.fields[tracksColumns["remixArtists"]];
      }

      if (record.fields[tracksColumns["album"]]) {
        track["Album"] = record.fields[tracksColumns["album"]];
      }

      if (record.fields[tracksColumns["albumArtwork"]]) {
        track["Album Artwork URL"] = record.fields[tracksColumns["albumArtwork"]][0].thumbnails.large.url;
      }

      reloadAudio(track);

    }).catch(err => {
      console.error(err); return;
    })

  }

}

function getTotalTracks() {

  base('All').find('recgZBgzmxBui4cZI', function(err, record) {
    if (err) { console.error(err); return; }

    // console.log('Retrieved', record.id);
    // console.log('Retrieved', record.fields["Total"]);

    Airwaves.totalTracks = record.fields["Total"];

  });

}

getTotalTracks();



// Play Track ID from URL
var url_string = window.location.href
var url = new URL(url_string);
var url_track = url.searchParams.get("track");

if (url_track) {
  // console.log(url_track);
  Airwaves.play(url_track);
}

document.onkeydown = function (e) {
  e = e || window.event;
  var key = e.which || e.keyCode;
  if (key === 32) {
    // check if an input is currently in focus
    if (
      document.activeElement.nodeName.toLowerCase() != "input" &&
      document.activeElement.nodeName.toLowerCase() != "audio"
    ) {

      // console.log('Hijacking spacebar for playPause toggle');

      // prevent default spacebar event (scrolling to bottom)
      e.preventDefault();

      Airwaves.playPause();
    }
  }
}



Airwaves.listNewTracks = function listNewTracks() {

  Airwaves.sendAlert('Refreshing new tracks');

  document.getElementById('newTracksResults').innerHTML = "";
  document.getElementById('newTracksResults').classList.add("is-loading");

  // console.log(columns);

  base('Tracks')
    .select({
      view: "⬇️ Recently Created",
      fields: tracksFieldsQuery
    })
    .firstPage(function (err, records) {

      if (err) { console.error(err); return; }

      var newTracks = [];

      records.forEach(function (record) {

        // console.log(record);

        var track = [];
        track["Title"] = "";
        track["Record ID"] = "";
        track["Play URL"] = "";
        track["Track Name"] = "";
        track["Track Type"] = "";
        track["Artists"] = "";
        track["Featured Artists"] = "";
        track["Remix Artists"] = "";
        track["Album"] = "";
        track["Album Artwork URL"] = "";

        if (tracksColumns["title"] in record.fields) {
          track["Title"] = record.get(tracksColumns["title"]);
        }

        if (tracksColumns["recordID"] in record.fields) {
          track["Record ID"] = record.get(tracksColumns["recordID"]);
        }

        // ["Play URL"]
        // Play URL = Track File [url] OR Google Drive URL
        if (tracksColumns["trackFile"] in record.fields) {
          // console.log("found trackfile in Airtable!");
          track["Play URL"] = record.get(tracksColumns["trackFile"])[0].url;
        } else {
          if(tracksColumns["googleDriveURL"] in record.fields)
          // console.log("found google drive trackfile!");
          track["Play URL"] = record.get(tracksColumns["googleDriveURL"]);
        }

        if (tracksColumns["trackName"] in record.fields) {
          track["Track Name"] = record.get(tracksColumns["trackName"]);
        }

        if (tracksColumns["trackType"] in record.fields) {
          track["Track Type"] = record.get(tracksColumns["trackType"]);
        }

        if (tracksColumns["artists"] in record.fields) {
          track["Artists"] = record.get(tracksColumns["artists"]);
        }

        if (tracksColumns["featuredArtists"] in record.fields) {
          track["Featured Artists"] = record.get(tracksColumns["featuredArtists"]);
        }

        if (tracksColumns["remixArtists"] in record.fields) {
          track["Remix Artists"] = record.get(tracksColumns["remixArtists"]);
        }

        if (tracksColumns["album"] in record.fields) {
          track["Album"] = record.get(tracksColumns["album"]);
        }

        if (tracksColumns["albumArtwork"] in record.fields) {

          if("thumbnails" in record.get(tracksColumns["albumArtwork"])[0]) {

            if("large" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
              track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.large.url;
            }

            else if("small" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
              track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.small.url;
            }
          }
          else {
            track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].url;
          }
        }

        Airwaves.trackCache.push(track);

        var trackItemTemplate = componentTemplate("trackItem", "", track);

        newTracks.push(trackItemTemplate);

      });

      document.getElementById('newTracksResults').classList.remove("is-loading");

      document.getElementById('newTracksResults').innerHTML = "<ul class='results__list results__list--grid'>" + newTracks.join("") + "</ul>";

    });

}

Airwaves.listNewTracks();

Airwaves.listNewPlaylists = function listNewPlaylists() {

  Airwaves.sendAlert('Refreshing new playlists');

  document.getElementById('newPlaylistsResults').innerHTML = "";
  document.getElementById('newPlaylistsResults').classList.add("is-loading");

  base('Playlists')
    .select({
      view: "📖 All Playlists",
      fields: playlistsFieldsQuery
    })
    .firstPage(function (err, records) {

      if (err) { console.error(err); return; }

      var newPlaylists = [];

      records.forEach(function (record) {

        var playlist = [];
        playlist["Name"] = "";
        playlist["Record ID"] = "";
        playlist["Track Artists"] = "";
        playlist["Track IDs"] = "";
        playlist["# Tracks"] = "";
        playlist["Album Artwork"] = "";

        if (playlistsColumns["name"] in record.fields) {
          playlist["Name"] = record.get(playlistsColumns["name"]);
        }

        if (playlistsColumns["recordID"] in record.fields) {
          playlist["Record ID"] = record.get(playlistsColumns["recordID"]);
        }

        if (playlistsColumns["trackArtists"] in record.fields) {
          // playlist["Track Artists"] = record.get(playlistsColumns["trackArtists"]);
          playlist["Track Artists"] = [...new Set(record.get(playlistsColumns["trackArtists"]).split(', '))].join(', ');
        }

        if (playlistsColumns["trackIDs"] in record.fields) {
          playlist["Track IDs"] = record.get(playlistsColumns["trackIDs"]);
        }

        if (playlistsColumns["trackCount"] in record.fields) {
          playlist["# Tracks"] = record.get(playlistsColumns["trackCount"]);
        }

        if (playlistsColumns["tracksAlbumArtworks"] in record.fields) {
          // console.log(record.get(playlistsColumns["tracksAlbumArtworks"]));

          var firstArtworkInLookup = record.get(playlistsColumns["tracksAlbumArtworks"])[0];

          if("thumbnails" in firstArtworkInLookup) {

            if("large" in firstArtworkInLookup.thumbnails) {
              playlist["Album Artwork"] = firstArtworkInLookup.thumbnails.large.url;
            }

            else if("small" in firstArtworkInLookup.thumbnails) {
              playlist["Album Artwork"] = firstArtworkInLookup.thumbnails.small.url;
            }
          }
          else {
            playlist["Album Artwork"] = firstArtworkInLookup.url;
          }
        }

        // Airwaves.albumCache.push(album);

        var playlistItemTemplate = componentTemplate("playlistItem", "", playlist);

        newPlaylists.push(playlistItemTemplate);

      });

      document.getElementById('newPlaylistsResults').classList.remove("is-loading");

      document.getElementById('newPlaylistsResults').innerHTML = "<ul class='results__list results__list--grid'>" + newPlaylists.join("") + "</ul>";

    });

}

Airwaves.listNewPlaylists();

Airwaves.listNewAlbums = function listNewAlbums() {

  Airwaves.sendAlert('Refreshing new albums');

  document.getElementById('newAlbumsResults').innerHTML = "";
  document.getElementById('newAlbumsResults').classList.add("is-loading");

  base('Albums')
    .select({
      view: "⬇️ Recently Created",
      fields: albumsFieldsQuery
    })
    .firstPage(function (err, records) {

      if (err) { console.error(err); return; }

      var newAlbums = [];

      records.forEach(function (record) {

        var album = [];
        album["Name"] = "";
        album["Record ID"] = "";
        album["Track Artists"] = "";
        album["Track IDs"] = "";
        album["Track Types"] = "";
        album["# Tracks"] = "";
        album["Album Artwork"] = "";

        if (albumsColumns["name"] in record.fields) {
          album["Name"] = record.get(albumsColumns["name"]);
        }

        if (albumsColumns["recordID"] in record.fields) {
          album["Record ID"] = record.get(albumsColumns["recordID"]);
        }

        if (albumsColumns["trackArtists"] in record.fields) {
          // album["Track Artists"] = record.get(albumsColumns["trackArtists"]);
          album["Track Artists"] = [...new Set(record.get(albumsColumns["trackArtists"]).split(', '))].join(', ');
        }

        if (albumsColumns["trackIDs"] in record.fields) {
          album["Track IDs"] = record.get(albumsColumns["trackIDs"]);
        }

        if (albumsColumns["trackTypes"] in record.fields) {
          album["Track Types"] = record.get(albumsColumns["trackTypes"]);
        }

        if (albumsColumns["trackCount"] in record.fields) {
          album["# Tracks"] = record.get(albumsColumns["trackCount"]);
        }

        if (albumsColumns["tracksAlbumArtworks"] in record.fields) {
          // console.log(record.get(albumsColumns["tracksAlbumArtworks"]));

          var firstArtworkInLookup = record.get(albumsColumns["tracksAlbumArtworks"])[0];

          if("thumbnails" in firstArtworkInLookup) {

            if("large" in firstArtworkInLookup.thumbnails) {
              album["Album Artwork"] = firstArtworkInLookup.thumbnails.large.url;
            }

            else if("small" in firstArtworkInLookup.thumbnails) {
              album["Album Artwork"] = firstArtworkInLookup.thumbnails.small.url;
            }
          }
          else {
            album["Album Artwork"] = firstArtworkInLookup.url;
          }
        }

        // Airwaves.albumCache.push(album);

        var albumItemTemplate = componentTemplate("albumItem", "", album);

        newAlbums.push(albumItemTemplate);

      });

      document.getElementById('newAlbumsResults').classList.remove("is-loading");

      document.getElementById('newAlbumsResults').innerHTML = "<ul class='results__list results__list--grid'>" + newAlbums.join("") + "</ul>";

    });

}

Airwaves.listNewAlbums();

function playNextTrackInQueueFuture() {

  Airwaves.play(Airwaves.queueFuture[0]["Record ID"], Airwaves.queueFuture.shift());

  renderQueueFuture();

}

function prepareAndPlayNextTrack() {

  if (Airwaves.queueFuture.length === 0) {

    // empty queueFuture?
    // 1. populate it
    // 2. play the first one

    populateQueueFuture(playNextTrackInQueueFuture);

  }

  else {

    playNextTrackInQueueFuture();

  }

  if (Airwaves.audio.paused === false) {

    document.querySelector('.playPause').classList.add('paused');

  } else {

    document.querySelector('.playPause').classList.remove('paused');

  }

}

Airwaves.addToQueue = function addToQueue(recordIDs) {
  
  Airwaves.sendAlert('Added to queue');

  let listToProcess = new Array();

  if (recordIDs.includes(',')) {
    // it's a list, push each one to the array
    recordIDs = recordIDs.split(',');
  }

  listToProcess.push(recordIDs);

  let filterFormula = "OR(";

  // console.log(listToProcess);

  for (let recordID of listToProcess.flat(1)) {

    // OR(FIND(record), {Record ID (Airtable)})
    filterFormula = filterFormula + "FIND('" + recordID + "', {Record ID (Airtable)}),";

  }

  filterFormula = filterFormula.slice(0, -1); // remove last comma
  filterFormula = filterFormula + ")" // close the OR()

  // console.log(filterFormula);

  base('Tracks')
  .select({
    view: "🔍 Has Artist & Album",
    filterByFormula: filterFormula,
    fields: tracksFieldsQuery
  })
  .firstPage(function (err, records) {

    if (err) { console.error(err); return; }

    if ( records.length > 0 ) {

      records.forEach(function (record) {

        var track = [];
        track["Title"] = "";
        track["Record ID"] = "";
        track["Play URL"] = "";
        track["Track Name"] = "";
        track["Track Type"] = "";
        track["Artists"] = "";
        track["Featured Artists"] = "";
        track["Remix Artists"] = "";
        track["Album"] = "";
        track["Album Artwork URL"] = "";

        if (tracksColumns["title"] in record.fields) {
          track["Title"] = record.get(tracksColumns["title"]);
        }

        if (tracksColumns["recordID"] in record.fields) {
          track["Record ID"] = record.get(tracksColumns["recordID"]);
        }
        
        // ["Play URL"]
        // Play URL = Track File [url] OR Google Drive URL
        if (tracksColumns["trackFile"] in record.fields) {
          // console.log("found trackfile in Airtable!");
          track["Play URL"] = record.get(tracksColumns["trackFile"])[0].url;
        } else {
          if(tracksColumns["googleDriveURL"] in record.fields)
          // console.log("found google drive trackfile!");
          track["Play URL"] = record.get(tracksColumns["googleDriveURL"]);
        }

        if (tracksColumns["trackName"] in record.fields) {
          track["Track Name"] = record.get(tracksColumns["trackName"]);
        }

        if (tracksColumns["trackType"] in record.fields) {
          track["Track Type"] = record.get(tracksColumns["trackType"]);
        }

        if (tracksColumns["artists"] in record.fields) {
          track["Artists"] = record.get(tracksColumns["artists"]);
        }

        if (tracksColumns["featuredArtists"] in record.fields) {
          track["Featured Artists"] = record.get(tracksColumns["featuredArtists"]);
        }

        if (tracksColumns["remixArtists"] in record.fields) {
          track["Remix Artists"] = record.get(tracksColumns["remixArtists"]);
        }

        if (tracksColumns["album"] in record.fields) {
          track["Album"] = record.get(tracksColumns["album"]);
        }

        if (tracksColumns["albumArtwork"] in record.fields) {

          if("thumbnails" in record.get(tracksColumns["albumArtwork"])[0]) {

            if("large" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
              track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.large.url;
            }

            else if("small" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
              track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.small.url;
            }
          }
          else {
            track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].url;
          }
        }

        Airwaves.trackCache.push(track);

        Airwaves.queueFuture.unshift(track);

        // var trackItemTemplate = componentTemplate("trackItem", "", track);

        // searchResultTracks.push(trackItemTemplate);
      });

      renderQueueFuture();

    } else {

    }

  });

  renderQueueFuture();
}

Airwaves.playPause = function playPause() {

  if (Airwaves.audio.paused === false) {

    pauseAudio();
    
  } else {

    playAudio();
    
  }
}

function playAudio() {
  Airwaves.audio.play();
  document.body.classList.add('is-playing');
  document.querySelector('.playPause').classList.remove('paused');
  navigator.mediaSession.playbackState = "playing";
}

function pauseAudio() {
  Airwaves.audio.pause();

    document.body.classList.remove('is-playing');
    document.querySelector('.playPause').classList.add('paused');
    navigator.mediaSession.playbackState = "paused";
}


Airwaves.ended = function ended() {

  // console.log('Audio ended');

  prepareAndPlayNextTrack();

}


Airwaves.skipTrack = function skipTrack() {

  // console.log('Track skipped');
  Airwaves.sendAlert('Track skipped');

  prepareAndPlayNextTrack();

}

Airwaves.seekForward = function seekForward() {

  // console.log('Seeked forward');

  Airwaves.audio.currentTime += 15.0;

}

Airwaves.seekBackward = function seekBackward() {

  // console.log('Seeked backward');

  Airwaves.audio.currentTime -= 15.0;

}

function renderQueuePast() {

  // clear queuepast
  document.getElementById('queuePast').innerHTML = "";

  // loop through new queuepast and render items
  Airwaves.queuePast.forEach((track) => {

    var trackItemTemplate = componentTemplate("trackItem", "", track);

    document.getElementById('queuePast').insertAdjacentHTML('beforeend', trackItemTemplate);
    
  });

}

function renderQueueCurrent() {

  var track = Airwaves.queueCurrent;

  var trackItemTemplate = componentTemplate("trackItem", "noControls", track);

  // clear, then update
  document.getElementById('queueCurrent').innerHTML = "";
  document.getElementById('queueCurrent').insertAdjacentHTML('beforeend', trackItemTemplate);

}

function renderQueueFuture() {

  // console.log('Start "renderQueueFuture"');

  // clear queueFuture
  document.getElementById('queueFuture').innerHTML = "";

  // loop through new queueFuture and render items
  Airwaves.queueFuture.forEach((track) => {

    var trackItemTemplate = componentTemplate("trackItem", "", track);

    document.getElementById('queueFuture').insertAdjacentHTML('beforeend', trackItemTemplate);
  });

}

function populateQueueFuture(callback) {

  // how many tracks do we need?

  var maxQueueFutureLength = 25;

  var queueFutureCount = Airwaves.queueFuture.length;

  var requiredTracksCount = maxQueueFutureLength - queueFutureCount;

  // console.log("Queue Future Count: " + queueFutureCount);
  // console.log("More Future Tracks Required: " + requiredTracksCount);

  if (requiredTracksCount > 0) {

    // make array of all records not allowed

    var disabledRecordIDs = [];
    Airwaves.queuePast.map(item => { disabledRecordIDs.push(item["Record ID"]) });
    Airwaves.queueFuture.map(item => { disabledRecordIDs.push(item["Record ID"]) });
    disabledRecordIDs.push(Airwaves.queueCurrent["Record ID"]);

    base('Tracks')
      .select({
        maxRecords: requiredTracksCount,
        view: "🔍 Has Artist & Album",
        filterByFormula: "AND(LOWER({" + tracksColumns["album"].replace(/'/g, "\\\'")  + "}) = LOWER('" + Airwaves.queueCurrent["Album"].replace(/'/g, "\\\'") + "'), FIND({" + tracksColumns["recordID"].replace(/'/g, "\\\'")  + "}, '" + disabledRecordIDs.join() + "') = 0)",
        fields: tracksFieldsQuery
      })
      .firstPage(function (err, records) {

        if (err) { console.error(err); return; }

        records.forEach(function (record) {

          // console.log('Matching track from album', record.get('Title'));

          // 1 less needed
          requiredTracksCount--;

          var track = [];
          track["Title"] = "";
          track["Record ID"] = "";
          track["Play URL"] = "";
          track["Track Name"] = "";
          track["Track Type"] = "";
          track["Artists"] = "";
          track["Featured Artists"] = "";
          track["Remix Artists"] = "";
          track["Album"] = "";
          track["Album Artwork URL"] = "";

          if (tracksColumns["title"] in record.fields) {
            track["Title"] = record.get(tracksColumns["title"]);
          }

          if (tracksColumns["recordID"] in record.fields) {
            track["Record ID"] = record.get(tracksColumns["recordID"]);
          }
          
          // ["Play URL"]
          // Play URL = Track File [url] OR Google Drive URL
          if (tracksColumns["trackFile"] in record.fields) {
            // console.log("found trackfile in Airtable!");
            track["Play URL"] = record.get(tracksColumns["trackFile"])[0].url;
          } else {
            if(tracksColumns["googleDriveURL"] in record.fields)
            // console.log("found google drive trackfile!");
            track["Play URL"] = record.get(tracksColumns["googleDriveURL"]);
          }

          if (tracksColumns["trackName"] in record.fields) {
            track["Track Name"] = record.get(tracksColumns["trackName"]);
          }

          if (tracksColumns["trackType"] in record.fields) {
            track["Track Type"] = record.get(tracksColumns["trackType"]);
          }

          if (tracksColumns["artists"] in record.fields) {
            track["Artists"] = record.get(tracksColumns["artists"]);
          }

          if (tracksColumns["featuredArtists"] in record.fields) {
            track["Featured Artists"] = record.get(tracksColumns["featuredArtists"]);
          }

          if (tracksColumns["remixArtists"] in record.fields) {
            track["Remix Artists"] = record.get(tracksColumns["remixArtists"]);
          }

          if (tracksColumns["album"] in record.fields) {
            track["Album"] = record.get(tracksColumns["album"]);
          }

          if (tracksColumns["albumArtwork"] in record.fields) {
            // console.log("found album artwork url!");

            if("thumbnails" in record.get(tracksColumns["albumArtwork"])[0]) {
              // console.log("has thumbnails!");

              if("large" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
                // console.log("has large thumbnail!");
                track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.large.url;
              }

              else if("small" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
                // console.log("has small thumbnail!");
                track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.small.url;
              }
            }
            else {
              // console.log("no thumbnails, just fullsize!");
              track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].url;
            }
          }

          Airwaves.trackCache.push(track);
          Airwaves.queueFuture.push(track);

          // console.log("Airwaves.queueFuture:");
          // console.table(Airwaves.queueFuture);

          // console.log("New Queue Future Count after Album Match: " + Airwaves.queueFuture.length);

          renderQueueFuture();

        });

        // console.log('After find album matches, we still need ' + requiredTracksCount);

        if (requiredTracksCount > 0) {

          // add any new IDs to the disabledRecordIDs array;
          Airwaves.queueFuture.map(item => { disabledRecordIDs.push(item["Record ID"]) });

          base('Tracks')
            .select({
              maxRecords: requiredTracksCount,
              view: "🔍 Has Artist",
              filterByFormula: "AND(FIND(LOWER('" + Airwaves.queueCurrent["Artists"].replace(/'/g, "\\\'") + "'), LOWER(" + tracksColumns["artists"].replace(/'/g, "\\\'")  + ")), FIND({" + tracksColumns["recordID"].replace(/'/g, "\\\'")  + "}, '" + disabledRecordIDs.join() + "') = 0)",
              fields: tracksFieldsQuery
            })
            .firstPage(function (err, records) {

              if (err) { console.error(err); return; }

              records.forEach(function (record) {

                // 1 less needed
                requiredTracksCount--;

                var track = [];
                track["Title"] = "";
                track["Record ID"] = "";
                track["Play URL"] = "";
                track["Track Name"] = "";
                track["Track Type"] = "";
                track["Artists"] = "";
                track["Featured Artists"] = "";
                track["Remix Artists"] = "";
                track["Album"] = "";
                track["Album Artwork URL"] = "";

                if (tracksColumns["title"] in record.fields) {
                  track["Title"] = record.get(tracksColumns["title"]);
                }

                if (tracksColumns["recordID"] in record.fields) {
                  track["Record ID"] = record.get(tracksColumns["recordID"]);
                }
                
                // ["Play URL"]
                // Play URL = Track File [url] OR Google Drive URL
                if (tracksColumns["trackFile"] in record.fields) {
                  // console.log("found trackfile in Airtable!");
                  track["Play URL"] = record.get(tracksColumns["trackFile"])[0].url;
                } else {
                  if(tracksColumns["googleDriveURL"] in record.fields)
                  // console.log("found google drive trackfile!");
                  track["Play URL"] = record.get(tracksColumns["googleDriveURL"]);
                }

                if (tracksColumns["trackName"] in record.fields) {
                  track["Track Name"] = record.get(tracksColumns["trackName"]);
                }

                if (tracksColumns["trackType"] in record.fields) {
                  track["Track Type"] = record.get(tracksColumns["trackType"]);
                }

                if (tracksColumns["artists"] in record.fields) {
                  track["Artists"] = record.get(tracksColumns["artists"]);
                }

                if (tracksColumns["featuredArtists"] in record.fields) {
                  track["Featured Artists"] = record.get(tracksColumns["featuredArtists"]);
                }

                if (tracksColumns["remixArtists"] in record.fields) {
                  track["Remix Artists"] = record.get(tracksColumns["remixArtists"]);
                }

                if (tracksColumns["album"] in record.fields) {
                  track["Album"] = record.get(tracksColumns["album"]);
                }

                if (tracksColumns["albumArtwork"] in record.fields) {
                  // console.log("found album artwork url!");

                  if("thumbnails" in record.get(tracksColumns["albumArtwork"])[0]) {
                    // console.log("has thumbnails!");

                    if("large" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
                      // console.log("has large thumbnail!");
                      track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.large.url;
                    }

                    else if("small" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
                      // console.log("has small thumbnail!");
                      track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.small.url;
                    }
                  }
                  else {
                    // console.log("no thumbnails, just fullsize!");
                    track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].url;
                  }
                }

                Airwaves.trackCache.push(track);
                Airwaves.queueFuture.push(track);

                renderQueueFuture();

              });

              // console.log('After find artist matches, we still need ' + requiredTracksCount);

              if (requiredTracksCount > 0) {

                var maxRandomTracks = 1;

                // add any new IDs to the disabledRecordIDs array;
                Airwaves.queueFuture.map(item => { disabledRecordIDs.push(item["Record ID"]) });

                // create string of random Autonumbers
                var matchAutonumberString = "";
                matchAutonumberString = matchAutonumberString + "OR(";

                for (let step = 0; step < maxRandomTracks; step++) {

                  matchAutonumberString = matchAutonumberString + "{Autonumber} = " + getRandomInt(0, Airwaves.totalTracks);

                  if (step !== maxRandomTracks - 1) {
                    matchAutonumberString = matchAutonumberString + ", ";
                  }

                  // console.log(matchAutonumberString);

                }

                matchAutonumberString = matchAutonumberString + ")";

                // console.log(matchAutonumberString);

                base('Tracks')
                  .select({
                    maxRecords: maxRandomTracks,
                    view: "🔍 Has Artist",
                    filterByFormula: "AND(" + matchAutonumberString.replace(/'/g, "\\\'") + ", FIND({" + tracksColumns["recordID"].replace(/'/g, "\\\'")  + "}, '" + disabledRecordIDs.join() + "') = 0)",
                    fields: tracksFieldsQuery
                  })
                  .firstPage(function (err, records) {

                    if (err) { console.error(err); return; }

                    records.forEach(function (record) {

                      // 1 less needed
                      maxRandomTracks--;

                      var track = [];
                      track["Title"] = "";
                      track["Record ID"] = "";
                      track["Play URL"] = "";
                      track["Track Name"] = "";
                      track["Track Type"] = "";
                      track["Artists"] = "";
                      track["Featured Artists"] = "";
                      track["Remix Artists"] = "";
                      track["Album"] = "";
                      track["Album Artwork URL"] = "";

                      if (tracksColumns["title"] in record.fields) {
                        track["Title"] = record.get(tracksColumns["title"]);
                      }

                      if (tracksColumns["recordID"] in record.fields) {
                        track["Record ID"] = record.get(tracksColumns["recordID"]);
                      }
                      
                      // ["Play URL"]
                      // Play URL = Track File [url] OR Google Drive URL
                      if (tracksColumns["trackFile"] in record.fields) {
                        // console.log("found trackfile in Airtable!");
                        track["Play URL"] = record.get(tracksColumns["trackFile"])[0].url;
                      } else {
                        if(tracksColumns["googleDriveURL"] in record.fields)
                        // console.log("found google drive trackfile!");
                        track["Play URL"] = record.get(tracksColumns["googleDriveURL"]);
                      }

                      if (tracksColumns["trackName"] in record.fields) {
                        track["Track Name"] = record.get(tracksColumns["trackName"]);
                      }

                      if (tracksColumns["trackType"] in record.fields) {
                        track["Track Type"] = record.get(tracksColumns["trackType"]);
                      }

                      if (tracksColumns["artists"] in record.fields) {
                        track["Artists"] = record.get(tracksColumns["artists"]);
                      }

                      if (tracksColumns["featuredArtists"] in record.fields) {
                        track["Featured Artists"] = record.get(tracksColumns["featuredArtists"]);
                      }

                      if (tracksColumns["remixArtists"] in record.fields) {
                        track["Remix Artists"] = record.get(tracksColumns["remixArtists"]);
                      }

                      if (tracksColumns["album"] in record.fields) {
                        track["Album"] = record.get(tracksColumns["album"]);
                      }

                      if (tracksColumns["albumArtwork"] in record.fields) {
                        // console.log("found album artwork url!");

                        if("thumbnails" in record.get(tracksColumns["albumArtwork"])[0]) {
                          // console.log("has thumbnails!");

                          if("large" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
                            // console.log("has large thumbnail!");
                            track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.large.url;
                          }

                          else if("small" in record.get(tracksColumns["albumArtwork"])[0].thumbnails) {
                            // console.log("has small thumbnail!");
                            track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].thumbnails.small.url;
                          }
                        }
                        else {
                          // console.log("no thumbnails, just fullsize!");
                          track["Album Artwork URL"] = record.get(tracksColumns["albumArtwork"])[0].url;
                        }
                      }

                      Airwaves.trackCache.push(track);
                      Airwaves.queueFuture.push(track);

                      renderQueueFuture();

                    });

                  });

              }

            });

        }

      });

  }

}

document.addEventListener('click', function (element) {

  // console.log(element.target.className);

  if (element.target.dataset.action === 'playTrack') {

    // console.log('clicked playTrack'); 
    Airwaves.play(element.target.dataset.recordid);

  }

  if (element.target.dataset.action === 'refreshNewTracks') {

    // console.log('clicked refreshNewTracks'); 
    Airwaves.listNewTracks();

  }

  if (element.target.dataset.action === 'refreshNewAlbums') {

    // console.log('clicked refreshNewAlbums'); 
    Airwaves.listNewAlbums();

  }

  if (element.target.dataset.action === 'addToQueue') {

    console.log('clicked addToQueue');
    Airwaves.addToQueue(element.target.dataset.recordid);

  }

  if (element.target.dataset.action === 'skipForward') {

    // console.log('clicked skipForward');
    Airwaves.audio.currentTime += 15.0;

  }

  if (element.target.dataset.action === 'skipBackward') {

    // console.log('clicked skipBackward');
    Airwaves.audio.currentTime -= 15.0;

  }

  if (element.target.dataset.action === 'skipTrack') {

    // console.log('clicked skipTrack');
    Airwaves.skipTrack();

  }

  if (element.target.dataset.action === 'playPause') {

    // console.log('clicked playPause');
    Airwaves.playPause();

  }

  if (element.target.dataset.action === 'search') {

    Airwaves.search();

  }

  if (element.target.dataset.action === 'clearQueue') {

    Airwaves.clearQueue();

  }

  if (element.target.dataset.action === 'shuffleQueue') {

    Airwaves.shuffleQueue();

  }
});

/*
if("serviceWorker" in navigator) {
  navigator.serviceWorker.register("sw.js").then(registration => {
    console.log("SW Registered success");
    console.log(registration);
  }).catch(error => {
    console.log("SW Registration failed");
    console.log(error);
  });
}
*/

/*
Airwaves.queueCurrent["Track Name"] = trackName;
Airwaves.queueCurrent["Artists"] = artist;
Airwaves.queueCurrent["Album"] = album;
Airwaves.queueCurrent["Album Artwork URL"] = albumArtworkURL;
*/

/*
if ('URLSearchParams' in window) {
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.set("track", recordID);
  window.location.search = searchParams.toString();
}
*/
